import React, { useEffect, useState } from 'react';
import validator from 'validator';


const UserForm = (props) => {

    const [email, setEmail] = useState(() => {
        const value = localStorage.getItem("email");
        if (value !== null) {
            return value;
        } else {
            return ''
        }
    });
    const [fullName, setFullName] = useState(() => {
        const value = localStorage.getItem("fullName");
        if (value !== null) {
            return value;
        } else {
            return ''
        }
    });

    useEffect(() => {
        localStorage.setItem("email", email)
    }, [email]);

    useEffect(() => {
        localStorage.setItem("fullName", fullName)
    }, [fullName]);


    const updateEmail = (email) => {
        setEmail(email);
    }

    const updateName = (name) => {
        setFullName(name)
    }

    const handleLogin = () => {

        if (validator.isEmail(email) && email.includes("@ndsu.edu")) {
            props.setInfo(email, fullName);
        } else {
            alert("Please enter a valide email address.");
        }
        
    }

    return (
        <div className='user-form card' style={{width: "25em"}}>
            <img className='card-img-top' src='./images/SoDAA Logo_green_bg.png' alt='logo'/>
            <div className="card-body">
                <h5 className='card-title'>Please enter your NDSU email and full name to continue:</h5>
                <div>
                    <p>demo user email: demo@ndsu.edu</p>
                    <p>demo user name: Demo User</p>
                </div>
                <br/>
                <div className="form-group">
                    <label>Email:</label>
                    <input className="form-control" type="email" value={email} onChange={e => {updateEmail(e.target.value)}}/>
                </div>
                <br/>
                <div className="form-group">
                    <label>Full Name:</label>
                    <input className="form-control" value={fullName} onChange={e => {updateName(e.target.value)}}/>
                </div>
                <br/>
                <br/>
                <div className='d-grid'>
                    <button className='btn btn-primary' onClick={handleLogin}>Continue</button>
                </div>
                
            </div>

        </div>
    )
}

export default UserForm;