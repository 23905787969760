import React, { useState } from 'react';

import db from './../../../../util/firebase';

const ManagerSetting = (props) => {
    const [fullName, setFullName] = useState(props.managerData.fullName);
    const [email, setEmail] = useState(props.managerData.email);
    const [phone, setPhone] = useState(props.managerData.phone);
    const [isChanged, setIsChanged] = useState(false);

    const handleChange = (e) => {
        switch(e.target.id) {
            case "fullName":
                setFullName(e.target.value);
                setIsChanged(true);
                break;
            case "email":
                setEmail(e.target.value);
                setIsChanged(true);
                break;
            case "phone":
                setPhone(e.target.value);
                setIsChanged(true);
                break;
            default:
                console.log("No matching.")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        db.ref(`/managers/${props.managerData.id}`).update({
            email: email,
            fullName: fullName,
            phone: phone
        }).then(() => {
            console.log("udpated");
            setIsChanged(false);
        });
    }


    return (
        <div>
            <p>Manager</p>
            <form onSubmit={handleSubmit}>

                <label>Full Name:</label>
                <input type="text" className='form-control' value={fullName} id="fullName" onChange={handleChange} />
                
                <label>Email:</label>
                <input type="text" className='form-control' value={email} id="email" onChange={handleChange} />
                
                <label>Phone:</label>
                <input type="text" className='form-control' value={phone} id="phone" onChange={handleChange} />
                <br/>
                <input type="submit" value="Update" className={isChanged ? "btn btn-primary" : "btn btn-primary disabled"}/>
            </form>
        </div>
    )
}

export default ManagerSetting;