import React from 'react';
import db from '../util/firebase';
import moment from 'moment';

import SpaceCard from './SpaceCard';
import DateSelector from './booking/DatePicker';
import TimePicker from './booking/TimePicker';
import BookingPreview from './BookingPreview';

import "./Booking.css"
// import { duration } from 'moment';

const queryParams = new URLSearchParams(window.location.search);
const spaceID = queryParams.get('spaceID');
// const spaceName = queryParams.get('spaceName');
const buildingName = queryParams.get('buildingName');
const buildingID = queryParams.get('buildingID');


const dateNow = new Date();
const dateNowString = moment(dateNow).format("YYYY-MM-DD");
const bookingData = {
    bookingID: Date.now(),
    spaceName: "",
    spaceID: spaceID,
    date: dateNowString,
    time: '',
    duration: 0,
    buildingName: buildingName,
    buildingID: buildingID
};

// Submit data on this page
class Booking extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingID: Date.now(),
            // spaceName: spaceName,
            spaceID: spaceID,
            date: dateNowString,
            time: '',
            duration: 0,
            previewDisplay: false,
            enablePreviewBtn: false,
            spaceData: {},
            reservationData: [],
            fullName: props.user.fullName,
            email: props.user.email
        };
        this.getReservationData = this.getReservationData.bind(this);
        this.getDate = this.getDate.bind(this);
        this.getTime = this.getTime.bind(this);
    }

    componentDidMount() {
        this.getReservationData();
        this.getSpaceData();
    }

    getReservationData = (date=this.state.date) => {
        console.log(`/reservations/${this.state.spaceID}/${date}`)
        db.ref(`/reservations/${this.state.spaceID}/${date}`)
            .once('value')
            .then( snapshot => {
                console.log(snapshot.val());
                if (snapshot.val() !== null) {
                    const bookingData = Object.values(snapshot.val());
                    console.log(bookingData);
                    this.setState({ reservationData: bookingData });
                } else {
                    this.setState({ reservationData: [] });
                }
                console.log(this.state.reservationData);
            })
            .catch( e => {
                console.log("Error fetching booking data", e);
            });
    }
    
    
    getSpaceData = () => {
        db.ref(`/buildings/${buildingID}/spaces/${this.state.spaceID}`)
            .once('value')
            .then( snapshot => {
                const spaceData = snapshot.val()
                console.log(spaceData);
                bookingData.spaceName = spaceData.name;
                this.setState({ spaceData: spaceData});
            })
            .catch( e => {
                console.log("Error fetching space data", e);
            });
    }


    getDate = selectedDate => {
        console.log(selectedDate);
        bookingData.date = selectedDate;
        this.setState({date: selectedDate});
        this.getReservationData(selectedDate);
    };

    getTime = (selectedSlots) => {
        console.log(selectedSlots);

        bookingData.time = selectedSlots[0];
        console.log(bookingData);

        this.setState({time: selectedSlots[0]});

        if (selectedSlots.length === 1) {
            this.setState({duration: 0.5});
            bookingData.duration = 0.5;
        } else if (selectedSlots.length > 1) {
            this.setState({duration: (selectedSlots[1] - selectedSlots[0] + 0.5)});
            bookingData.duration = (selectedSlots[1] - selectedSlots[0] + 0.5);
        }

        if (selectedSlots.length > 0) {
            this.setState({enablePreviewBtn: true});
        } else {
            this.setState({enablePreviewBtn: false});
        }
        
    };

    preview = () => {
        this.setState({previewDisplay: true});
    }


    togglePop = () => {
        this.setState({
            previewDisplay: !this.state.previewDisplay
        });
    };


    render () {
        bookingData.email = this.props.user.email;
        bookingData.fullName = this.props.user.fullName;
        return (
            <div className='container'>
                <div className='title-container d-flex justify-content-between'>
                    <div><p>{buildingName}</p></div>
                    <h4>Select your date and time.</h4>
                </div>
                <div className='row booking-container'>
                    <div className='col-sm-4'>
                        <SpaceCard key={this.state.spaceData.spaceID} 
                        name={this.state.spaceData.name} 
                        buildingID={this.state.building} 
                        buildingName={buildingName} 
                        room={this.state.spaceData.room} 
                        isPermitted={true} 
                        image={this.state.spaceData.image}
                        spaceID={this.state.spaceData.spaceID}
                        parent="booking"/>
                    </div>
                    <div className='col-sm-8'>
                        <DateSelector dateSelected={this.getDate}/>

                        <TimePicker timeSelected={this.getTime} spaceID={spaceID} date={this.state.date} reservationData={this.state.reservationData} spaceData={this.state.spaceData}/>

                        {this.state.previewDisplay 
                            ? <div className="d-flex justify-content-center" id='previewContainer'><BookingPreview bookingData={bookingData} spaceData={this.state.spaceData} org="booking" toggle={this.togglePop}/></div>
                            : null}

                        <div className='button-container d-flex justify-content-between'>
                            <a className='link-building' href={`/spaces?buildingID=${buildingID}&buildingName=${buildingName}`}>Select another space</a>
                            {this.state.enablePreviewBtn ? <button className='btn btn-primary btn-preview' onClick={this.togglePop}>Preview</button> : <button className='btn btn-primary btn-preview' disabled>Preview</button>}
                        </div>
                    </div>

                    

                    
                </div>
                
                
            </div>
        )
    }

    
}

export default Booking;


