import React from 'react';
import db from './../util/firebase';

import BookingPreview from './BookingPreview';

const queryParams = new URLSearchParams(window.location.search);
const spaceID = queryParams.get('spaceID');
const date = queryParams.get('date');
const bookingID = queryParams.get('bookingID');


class Success extends React.Component {
    constructor() {
        super();
        this.state = {
            bookingData: {}
        }
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData(`${spaceID}/${date}/${bookingID}`);
    }


    loadData = (location) => {
        db.ref(`/reservations/${location}`).once("value").then(snapshot => {
            console.log(snapshot.val());
            this.setState({bookingData: snapshot.val()});
        });
    }

    displayBookingData = () => {
        // console.log(this.state.bookingData);
        return (
            
            <div>
               {/* <p>{JSON.stringify(this.state.bookingData)}</p> */}
               <BookingPreview bookingData={this.state.bookingData} spaceData={null} toggle={this.togglePop} org="success"/>
            </div>
        )
    }

    togglePop = () => {
        this.setState({
            previewDisplay: !this.state.previewDisplay
        });
    };

    
    render() {
        return (
            <div style={{maxWidth: "36em", width: "100%"}}>
                <h1 className='text-center'>Success!</h1>
                <h4 className='text-center'>You can save this page as the record of your reservation.</h4>
                <br/>
                <div>
                    {this.displayBookingData()}

                    {/* send a confirmation email to the user. */}
                    {/* display a button that download a .ics file. */}
                </div>
                
            </div>
        )
    }
    
}

export default Success;