import React from 'react';
import UserForm from './UserForm';

class Home extends React.Component {

  constructor(props){
    super(props);
  }


  render(){

    console.log(this.props.user.email);
    return (
      <div className="card-container">
        {/* <UserForm parent="home" /> */}
      </div>
    );
  }
    
}

export default Home;