import React from "react";
import timeConverter from './../../../../util/timeConventer';


class ScheduleBtn extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            btnStatus: props.isActive ? "btn-active" : "btn-inactive"
        };
        this.toggleSelection = this.toggleSelection.bind(this);
    }

    toggleSelection(){
        if(this.state.btnStatus === "btn-inactive") {
            this.setState({btnStatus: "btn-active"});
        } else {
            this.setState({btnStatus: "btn-inactive"});
        }
        this.props.setTime(this.props.hour, this.props.day);
        
    }

    timeSelectorBtn (){
        if(this.props.hour % 1 === 0) {
            return (<div key={this.props.hour} 
                className={`time-picker-btn ${this.state.btnStatus} picker-left`}
                onClick={this.toggleSelection}>
                <p className="timepicker-time">{timeConverter(this.props.hour)}</p>
            </div>);
        } else {
            return (<div key={this.props.hour} 
                className={`time-picker-btn ${this.state.btnStatus} picker-right`}
                onClick={this.toggleSelection}></div>);
        }
        
    }

    render() {
        return (
            this.timeSelectorBtn()
        )
    }
    
}

export default ScheduleBtn;