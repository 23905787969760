import React from 'react';
import db from './../util/firebase';
import toDisplayTime from '../util/timeConventer';

import SpaceCard from './SpaceCard';

import './Card.css'

const queryParams = new URLSearchParams(window.location.search);
const buildingID = queryParams.get('buildingID');
const buildingName = queryParams.get('buildingName');

// TODO: load space data from database

class Spaces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spaceData: []
    }
    this.loadSpaceData = this.loadSpaceData.bind(this);
  }

  componentDidMount() {
    this.loadSpaceData();
  }

  loadSpaceData = () => {
    db.ref(`/buildings/${buildingID}`).once("value").then(snapshot => {
      console.log(snapshot.val());
      console.log(this.props.user)
      this.setState({spaceData: Object.values(snapshot.val().spaces)});
    });
  }

  handleContainerDisplay () {
      return (
          this.state.spaceData.map(space => {
            return <div className='card-wrapper'>
                <SpaceCard key={space.id} 
                  name={space.name} 
                  buildingID={buildingID} 
                  image={space.image}
                  buildingName={buildingName} 
                  room={space.room} 
                  isPermitted={false} 
                  opentime={toDisplayTime(space.schedule[0][0])} 
                  closetime={toDisplayTime(space.schedule[0][space.schedule[0].length - 1])} 
                  spaceID={space.id}/>
            </div>
            
            
          })
        )
  }

  render() {
    return (
      <div className="container-fluid">

        <div className='title-container d-flex justify-content-between'>
          <div><p>{buildingName}</p></div>
          <h4>Select the space you want to use.</h4>
        </div>
        
        <div className='main-container container-fluid d-flex justify-content-center building-list row text-center' style={{border: "1px solid #2EA279", borderRadius: "12px", padding: "40px"}}>
        {this.handleContainerDisplay()}
        </div>
        <br/>
        <a href='/ndsu' className='btn btn-outline-primary btn-back'>Select another building</a>
      </div>
      //TODO: check the student's permission for the space and display accordingly. 
        
      );
  }
}

export default Spaces;