import React, { useState } from "react";

import Route from './util/Route';
import Home from './components/Home';
import Buildings from './components/Buildings';
import Spaces from './components/Spaces';
import Booking from './components/Booking';
import Success from './components/Success';
import Cancel from './components/Cancel';

import ManageUsers from './components/manage/ManageUsers';
import ManageDashboard from './components/manage/ManageDashboard';
import AdminPanel from "./components/admin/AdminPanel";

import UserForm from "./components/UserForm";

import './App.css';
import Reservations from "./components/Reservations";

const App = () => {

    const [email, setEmail] = useState(() => {
        const value = localStorage.getItem("email");
        if (value !== null) {
            return value;
        } else {
            return ''
        }
    });

    const [fullName, setFullName] = useState(() => {
        const value = localStorage.getItem("fullName");
        if (value !== null) {
            return value;
        } else {
            return ''
        }
    });

    function setUserInfo(enteredEmail, enteredName){
        setEmail(enteredEmail);
        setFullName(enteredName);
    }

    function handleNavbar(){
        if (email === '' && fullName === ''){
            return (
                <div className="container-fluid">
                    <h5 className="navbar-brand">Simple Booking</h5>
                    <a href="/ndsu">New Booking</a>
                    <p>-</p>
                </div>
            );
        } else {
            return (
                <div className="container-fluid">
                    <h5 className="navbar-brand">Simple Booking</h5>
                    <a href="/ndsu">New Booking</a>
                    <button className="btn btn-outline-primary" onClick={logout}>Logout</button>
                </div>
            )
        }
    }

    function handleLoginForm(){
        console.log(email);
        if(email === '' && fullName === '') {
            return (<div className="container d-flex justify-content-center" style={{paddingTop: "30px"}}>
                    <UserForm setInfo={setUserInfo} />
                </div>) 
        } else {
            return (<div className="container d-flex justify-content-center" style={{paddingTop: "30px"}}>

                    <Route path='/'>
                        <Buildings user={{email: email, fullName: fullName}} />
                    </Route>
                    <Route path='/ndsu'>
                        <Buildings user={{email: email, fullName: fullName}}/>
                    </Route>
                    <Route path='/spaces' >
                        <Spaces user={{email: email, fullName: fullName}}/>
                    </Route>
                    <Route path='/booking' >
                        <Booking user={{email: email, fullName: fullName}} spaceID='1' date={Date.now()}/>
                    </Route>
                    <Route path='/success' > 
                        <Success user={{email: email, fullName: fullName}} />
                    </Route>
                    <Route path='/cancel' > 
                        <Cancel user={{email: email, fullName: fullName}} />
                    </Route>
                    <Route path='/reservations'>
                        <Reservations user={{email: email, fullName: fullName}} />
                    </Route>

                    {/* Management Portal: */}
                    <Route path='/manage' > 
                        <ManageDashboard user={{email: email, fullName: fullName}} />
                    </Route>
                    <Route path='/manage/users' > 
                        <ManageUsers user={{email: email, fullName: fullName}} />
                    </Route>
                    <Route path='/admin' > 
                        <AdminPanel user={{email: email, fullName: fullName}} />
                    </Route>
            </div> )
        }
        
    }

    function logout(){
        setEmail('');
        setFullName('');
    }


    return (<div>
        <nav className="navbar bg-light">
            {handleNavbar()}
        </nav>
        {handleLoginForm()}
        <footer>
            <br/>
        </footer>
    </div>)
}

export default App;