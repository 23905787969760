import React from 'react';

import db from './../util/firebase';

import BuildingCard from './BuildingCard';

import './Card.css'


class SpacePage extends React.Component{
  constructor(props) {
    super(props);
    this.state ={
      buildingList: []
    }
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
      this.loadData();
  }


  loadData = () => {
      db.ref(`/buildings`).once("value").then(snapshot => {
          // console.log(snapshot.val());
          this.setState({buildingList: Object.values(snapshot.val())});
      });
  }

  enterBuilding = (url) => {
    window.open(url);
  }

  displayBuildingCards = () => {
    // console.log(this.state.bookingData);
    console.log(this.props.user);
    return (
          this.state.buildingList.map(building => <div className='card-wrapper'><BuildingCard key={building.id} buildingData={building} /></div>)
    )
    
}

  render() {
    return (
      <div className="container-fluid">
        
        <div className='title-container d-flex justify-content-between'>
          <div><p>NDSU</p></div>
          <h4>Select your building to start.</h4>
        </div>
        <div className='main-container container-fluid d-flex justify-content-center building-list row text-center' style={{border: "1px solid #2EA279", borderRadius: "12px", padding: "40px"}}>
        {this.displayBuildingCards()}
        </div>
      </div>
    );
  }
}

export default SpacePage;

