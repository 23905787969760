import React from 'react';

import db from './../../../util/firebase';

import SpaceInfoSetting from './setting/SpaceInfoSetting';
import ManagerSetting from './setting/ManagerSetting';
import SpaceSchedule from './setting/SpaceSchedule';

class SettingPanel extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            managerData: {},
            spaceInfo: {},
            buildingData: {}
        }
        this.loadData = this.loadData.bind(this);
        this.updateSchedule = this.updateSchedule.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        db.ref(`/managers/${this.props.managerID}`)
        .once("value")
        .then(snapshot => {
            // console.log(snapshot.val());
            this.setState({managerData:snapshot.val()});
        });

        db.ref(`/buildings`)
        .once("value")
        .then(snapshot => {
            const data = snapshot.val();
            const spaceData = data[this.props.buildingID]["spaces"][this.props.spaceID];
            // console.log(spaceData);
            this.setState({spaceInfo:spaceData});
            this.setState({buildingData: data});
        });

    }

    updateSchedule(time, currentDay) {

        let newSchedule = this.state.spaceInfo.schedule[currentDay].openSlots;

        if (newSchedule.includes(time)){
            newSchedule.splice(newSchedule.indexOf(time), 1);
        } else {
            newSchedule.push(time);
        }

        db.ref(`/buildings/${this.props.buildingID}/spaces/${this.props.spaceID}/schedule/${currentDay}/openSlots`).set(newSchedule).then(() => {
            console.log("udpated");
        });
    }

    showManagerSetting() {
        if (Object.keys(this.state.managerData).length > 0) {
            return <ManagerSetting managerData={this.state.managerData}/>
        } 
    }

    showSpaceInfoSetting(){
        if (Object.keys(this.state.spaceInfo).length > 0 && Object.keys(this.state.buildingData).length) {
            return (<div>
                <SpaceInfoSetting spaceInfo={this.state.spaceInfo} buildingData={this.state.buildingData}/>
                <SpaceSchedule schedule={this.state.spaceInfo.schedule} setTime={this.updateSchedule}/>
            </div>)
        } 
    }

    render() {
        return (
            <div>
                <h3>Setting Panel</h3>
                <button>Close</button>
                {this.setState.managerData}
                <div id="settingPanel">
                    {this.showSpaceInfoSetting()}
                    {this.showManagerSetting()}
                </div>
            </div>
        )
    }
}

export default SettingPanel;