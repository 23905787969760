const toDayName = (num, isShort=true) => {
    const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Satuday","Sunday" ];
    const dayNamesShort = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    if (isShort) {
        return dayNamesShort[num];
    } else {
        return dayNames[num];
    }
    
    
}

export default toDayName;