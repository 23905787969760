import React from 'react';
import moment from 'moment';
import toDisplayTime from './../../../util/timeConventer';

import TimeRow from './timeTable/TimeRow'


class TimeTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            schedule: []
        }
    }

    getSchedule(date) {

    }

    loadTimeRow(){
        const dailySchedule = this.props.spaceData.schedule;
        
        if (dailySchedule) {
            const schedule = dailySchedule[moment(this.props.date).day() - 1];
            
            const slots = [];

            if (schedule.isOpen) {

                for(let i = Math.min(...schedule.openSlots); i <= Math.max(...schedule.openSlots); i += 0.5) {
                    slots.push({
                        time: i,
                        displayTime: toDisplayTime(i),
                        reservations: []
                    });
                }
    
                const bookingData = this.props.bookingData;
                
                if (bookingData !== null) {
                    Object.values(bookingData).forEach(booking => {
                        // console.log(booking);
                        const bookedTimes = [];
                        for(let i = booking.time; i < booking.time + booking.duration; i += 0.5) {
                            bookedTimes.push(i);
                        }
                        // console.log(bookedTimes);
                        slots.forEach(slot => {
                            if(bookedTimes.includes(slot.time)) {
                                slot.reservations.push(booking);
                            }
                        })
                    });
                }
                
                return slots.map(slot => {
                    return (
                        <TimeRow key={slot.time} 
                                reservations={slot.reservations} 
                                time={slot.time} 
                                displayTime={slot.displayTime}
                                spaceID={this.props.spaceData.id}
                                date={this.props.date}/>
                    )
                })
            }
            
        }
        
    }

    render() {
        return (
            <div>
                <h3>Time Table</h3>
                <div>
                    {this.loadTimeRow()}
                </div>
            </div>
        )
    }
}

export default TimeTable;