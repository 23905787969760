import React from 'react';
import { ics } from 'ics-file-export';
import db from '../util/firebase';
import toDisplayDate from '../util/dateConverter';
import toDisplayTime from '../util/timeConventer';
import checkAvailability from '../util/checkAvailability';

const BookingPreview = (props) => {

    const userID = "nwnhqlmxH9a5HRtCkkVKlVXBZHC3";
    const CANCEL_URL = "http://localhost:3000/cancel";
    const HISTORY_URL = "http://localhost:3000/reservations";

    const submitData = (data) => {

        checkAvailability(props.spaceData.capacity, data.spaceID, data.date, () => {

            db.ref(`/reservations/${data.spaceID}/${data.date}/${data.bookingID}`).set(data).then(() => {

                db.ref(`/users/${userID}/reservations`).push({
                    bookingID: data.bookingID,
                    spaceID: data.spaceID,
                    date: data.date
                }).then(() => {
                    console.log("submitted.", data);

                    // TODO: send an email.

                    window.open(`/success?spaceID=${data.spaceID}&date=${data.date}&bookingID=${data.bookingID}`, "_self");
                });

            }); 
            
        });
        
    }

    const handleClick = () => {
        props.toggle();
       };
    
    const loadBookingData = (data) => {
        console.log(props);
        return (
            <div id="bookingDetails" style={{width: "16em"}}>
                <h2 className='card-title'>{data.spaceName}</h2>
                <p><b>Building</b>: {data.buildingName}</p>
                <p><b>Date</b>: {toDisplayDate(data.date)}</p>
                <p><b>Time</b>: {toDisplayTime(data.time)}</p>
                <p><b>Duration</b>: {data.duration} Hours</p>
                <p><b>Full Name</b>: {data.fullName}</p>
                <p><b>Email</b>: {data.email}</p>
            </div>
        )
    }

    const downloadICS = () => {
        const cal = ics();

        const icsMsg = `
            Your reservation is made successfully. Here are the details:\\n
            Space: ${props.bookingData.spaceName} \\n
            Building: ${props.bookingData.buildingName}\\n
            Date: ${toDisplayDate(props.bookingData.date)}\\n
            Time: ${toDisplayTime(props.bookingData.time)}\\n
            Duration: ${props.bookingData.duration} hour(s)\\n
            Full Name: ${props.bookingData.fullName} hour(s)\\n\\n
            Cancel this reservation: ${CANCEL_URL}?spaceID=${props.bookingData.spaceID}&date=${props.bookingData.date}&bookingID=${props.bookingData.bookingID}\\n
            View your reservation history: ${HISTORY_URL}?userID=${userID}
        `

        cal.addEvent(
            `Reservation: ${props.bookingData.spaceName}`,
            icsMsg,
            `${props.bookingData.buildingName}`,
            `${toDisplayDate(props.bookingData.date)} ${toDisplayTime(props.bookingData.time)}`, 
            `${toDisplayDate(props.bookingData.date)} ${toDisplayTime(props.bookingData.time + props.bookingData.duration)}`
        );

        // initiates download in client
        cal.download();
    }
    
    const handleBtnDisplay = () => {
        if (props.org === "booking") {
            return (
                <div className="d-flex justify-content-around" style={{marginTop: "20px"}}>
                    <button className='btn btn-secondary' style={{paddingLeft: "20px", paddingRight: "20px"}} onClick={handleClick}>Back</button>
                    <button className='btn btn-primary' style={{paddingLeft: "20px", paddingRight: "20px"}} onClick={() => {submitData(props.bookingData)}}>Submit</button>
                </div>
            )
        } else if (props.org === "success") {
            return (
                <div className="d-flex justify-content-between" style={{marginTop: "20px"}}>
                    <a href={`/cancel?spaceID=${props.bookingData.spaceID}&date=${props.bookingData.date}&bookingID=${props.bookingData.bookingID}`}>Cancel This</a>
                    <button className='btn btn-primary' onClick={downloadICS}>Add to my calendar</button>
                </div>
            )
        } else if (props.org === "cancel") {
            return null
        }
    }

    // console.log(props);
    return (
        <div className="d-flex justify-content-center">
            <div className='popup booking-preview card' style={{width: "24rem", backgroundColor: "white", height: "360px"}}>
            <div className='card-body'>
                <p>Booking Details</p>
                {loadBookingData(props.bookingData)}
                <br/>
                {handleBtnDisplay()}
            </div>
            
        </div>
        </div>
        
    )
}

export default BookingPreview;