import React from 'react';

import db from './../../util/firebase';
import spaceTemplate from './../../assets/initData.json';

class AdminPanel extends React.Component {
    constructor(){
        super();
    }

    loadData(){
        console.log(spaceTemplate);
    }
    
    render() {
        return <div>AdminPanel {this.loadData()}</div>
    }
}

export default AdminPanel;