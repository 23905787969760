import React from 'react';

const queryParams = new URLSearchParams(window.location.search);
const userID = queryParams.get('userID');

const Reservations = () => {
    console.log(userID);
    return (
        <div className="card-container">
          <h2>Reservation History</h2>
          <p>User Email: zhenhua.yang.1@ndsu.edu</p>
          <p>Full Name: Aaron Yang</p>
          <ul>
            <li>Reservation 1 <a href={`/cancel?id=${1}`}>Cancel</a></li>
            <li>Reservation 2 <a href={`/cancel?id=${2}`}>Cancel</a></li>
            <li>Reservation 3 <a href={`/cancel?id=${3}`}>Cancel</a></li>
          </ul>
        </div>
      );
}

export default Reservations;