import React, {useState} from 'react';

import db from './../../../../util/firebase';

const SpaceInfoSetting = (props) => {
    const [name, setName] = useState(props.spaceInfo.name);
    const [capacity, setCapacity] = useState(props.spaceInfo.capacity);
    const [needPermission, setneedPermission] = useState(props.spaceInfo.needPermission);
    const [building, setBuilding] = useState(props.spaceInfo.building);
    const [room, setRoom] = useState(props.spaceInfo.room);
    const [website, setWebsite] = useState(props.spaceInfo.website ? props.spaceInfo.website : '');

    const [isChanged, setIsChanged] = useState(false);

    const handleChange = (e) => {
        switch(e.target.id) {
            case "name":
                setName(e.target.value);
                setIsChanged(true);
                break;
            case "capacity":
                setCapacity(e.target.value);
                setIsChanged(true);
                break;
            case "needPermission":
                setneedPermission(e.target.value);
                setIsChanged(true);
                break;
            case "building":
                setBuilding(e.target.value);
                setIsChanged(true);
                break;
            case "room":
                setRoom(e.target.value);
                setIsChanged(true);
                break;
            case "website":
                setWebsite(e.target.value);
                setIsChanged(true);
                break;
            default:
                console.log("No matching.")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        db.ref(`/buildings/${props.spaceInfo.building}/spaces/${props.spaceInfo.id}`).update({
            name: name,
            needPermission: needPermission,
            capacity: capacity,
            room: room,
            building: building,
            website: website,
        }).then(() => {
            console.log("udpated");
            setIsChanged(false);
        });
    }

    
    const buildingList = ()=> {
        return (
            Object.values(props.buildingData).map( data => {
                return <option key={data.id} value={data.id}>{data.name}</option>
            })
        )
        
    }

    return (
        <div>
            <p>Space Information</p>
            <form onSubmit={handleSubmit}>

                <div className='row'>
                    <div className='form-group col'>
                        <label>Space Name:</label>
                        <input type="text" className='form-control' value={name} id="name" name="name" onChange={handleChange} />
                    </div>
                    <div className='form-group col'>
                        <label>Capacity:</label>
                        <input type="number" step={1} className='form-control' value={capacity} id="capacity" name="capacity" onChange={handleChange} />
                    </div>
                    <div className='form-group col'>
                        <label>Permission Required?</label>
                        <select className='form-control' id="needPermission" value={needPermission} name="needPermission" onChange={handleChange}>
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </div>
                </div>
                
                <div className='row'>
                    <div className='form-group col-8'>
                        <label>Building:</label>
                        <select type="text" className='form-control' value={building} id="building" name="building" onChange={handleChange} >
                            {buildingList()}
                        </select>
                    </div>
                    <div className='form-group col-4'>
                        <label>Room:</label>
                        <input type="test" className='form-control' value={room} id="room" name="room" onChange={handleChange} />
                    </div>
                    
                </div>

                <div className='row'>
                    <div className='form-group col'>
                            <label>Website</label>
                            <input type="text" className='form-control' value={website} id="website" name="website" onChange={handleChange} />
                    </div>
                </div>
                
                <div className='row'>
                    <div className='col'>
                        <input type="submit" value="Update" className={isChanged ? "btn btn-primary" : "btn btn-primary disabled"}/>
                    </div>
                    
                </div>
            </form>
        </div>
    )
}

export default SpaceInfoSetting;