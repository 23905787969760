export default function getOccupation(schedule, currentReservations, minDur) {

    const ocupation = [];

    schedule.forEach(slot => {
        const obj = {};
        obj.time = slot;
        obj.count = 0;
        ocupation.push(obj);
    });

    const bookedContainer = [];

    currentReservations.forEach(reservation => {
        if (!reservation.canceled) {
            for ( let i = 0; i < reservation.duration; i+=minDur) {
                bookedContainer.push(reservation.time + i);
            }
        }
            
    });

    bookedContainer.forEach(time => {
        ocupation.forEach(slot => {
            if(slot.time === time) {
                slot.count++;
            }
        })
    })

    console.log(ocupation);

    return ocupation;
}