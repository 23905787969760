import React from 'react';

const ManageStudents = () => {
    return (
        <div className="card-container">
          <h1>ManageStudents</h1>
          <button className='btn'>Export all users as a .csv file</button>
          <button className='btn'>Print a user list</button>
        </div>
      );
}

export default ManageStudents;