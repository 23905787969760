import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

const datePickerStyle = {
    marginTop: "20px",
    marginBottom: "20px"
}


const DateSelector = (props) => {
    // const [startDate, setStartDate] = useState(new Date());

    const [startDate, setStartDate] = useState(new Date());
    
    const setDate = (date) => {
        setStartDate(date);
        props.dateSelected(moment(date).format('YYYY-MM-DD'));
    }

    return (
        <div id='dateSelector' style={datePickerStyle}>
            <p>Date: </p>
            <DatePicker 
                selected={startDate}
                onChange={(startDate) => {
                    setDate(startDate)
                }} />
        </div>
        
    )
}

export default DateSelector;