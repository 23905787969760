import React from 'react';
import "./TimePicker.css"
import toDisplayTime from '../../util/timeConventer';
import getOccupation from '../../util/getOccupation';
import moment from 'moment';

// import db from '../../util/firebase';


class TimePicker extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props);
        console.log(moment(this.props.date).day()-1)
    }

    timeArray = [];
    isSelectionCenceled = false;
    
    deselectBtns = () => {
        console.log("buttons dislected");
        document.querySelectorAll(".time-btn").forEach(btn => {
            if(btn.classList.contains("selected")) {
                btn.classList.remove("selected");
            }
        });
    };

    // TODO: auto-select all buttons between two selected buttons
    btnClicked = (event) => {

        const btn = event.target;
        if (btn.classList.contains("open")) {

            if (btn.classList.contains("selected")) {

                if (this.timeArray.length === 2 || this.timeArray.length === 1) {
                    this.deselectBtns();
                    this.timeArray = [];
                }

                this.timeArray.splice(this.timeArray.indexOf(Number(event.target.dataset.value)), 1);
                this.props.timeSelected(this.timeArray);

                
            } else {

                const timeValue = Number(event.target.dataset.value);

                if (this.timeArray.length === 0) {

                    this.timeArray.push(timeValue);

                } else if (this.timeArray.length === 1) {

                    if (timeValue > this.timeArray[0]) {
                        this.timeArray.push(timeValue);
                    } else {
                        this.timeArray.unshift(timeValue);
                    }

                    const distance = Math.abs(this.timeArray[0] - this.timeArray[1]);
                    if ( distance > 0) {
                        for (let i = Math.min(...this.timeArray); i < Math.max(...this.timeArray); i+=0.5) {
                            const btnAutoSelected = document.querySelector(`[data-value="${i}"]`);

                            if (btnAutoSelected.classList.contains("close")) {
                                alert("Some time slots are not available in this peroid. Please select another time peroid.");
                                this.isSelectionCenceled = true;
                                break;
                            } else {
                                document.querySelector(`[data-value="${i}"]`).classList.add("selected");
                            }
                            
                        }
                    }

                } else if (this.timeArray.length === 2) {

                    this.deselectBtns();
                    this.timeArray = [];
                    this.timeArray.push(timeValue);

                }
                
                this.props.timeSelected(this.timeArray);
                btn.classList.add("selected");
            }

            if (this.isSelectionCenceled) {
                this.deselectBtns();
                this.timeArray = [];
                this.isSelectionCenceled = false;
            }
            
        }

        console.log(this.timeArray);
    };

    createBtnList = () => {

        if (Object.keys(this.props.spaceData).length > 0) {

            let capacity, minDur, sortedDailySchedule;

            capacity = this.props.spaceData.capacity;
            minDur = this.props.spaceData.minDuration;

            const currentSchedule = this.props.spaceData.schedule[moment(this.props.date).day()];

            if (currentSchedule.isOpen) {
                sortedDailySchedule = currentSchedule.openSlots.sort((a, b) => a - b);

                return getOccupation(sortedDailySchedule, this.props.reservationData, minDur).map(slot => {
                    
                    if(slot.time === sortedDailySchedule[sortedDailySchedule.length - 1]) {
                        return (<span
                            className="btn-end"
                            key={slot.time}>
                            <p className='time-label'>{toDisplayTime(slot.time)}</p>
                            </span>)
                    }
                    if (slot.count >= capacity) {
                        return (<button
                            disabled
                            key={slot.time} 
                            className={"time-btn close"}
                            data-value={slot.time}
                            onClick={(e) => this.btnClicked(e)}>
                            <p className='time-label'>{toDisplayTime(slot.time)}</p>
                            </button>)
                    } else {
                        return (<button
                            key={slot.time} 
                            className={"time-btn open"}
                            data-value={slot.time}
                            onClick={(e) => this.btnClicked(e)}>
                            <p className='time-label'>{toDisplayTime(slot.time)}</p>
                            </button>)
                    }
        
                });

            } else {
                return <h4><br/>This space is closed during the date you selected.<br/><br/></h4>;
            }

            

            
        }
        
    };

    // getData = () => {
    //     console.log(this.props.spaceID, this.props.date);
    //     db.ref(`/reservations/${this.props.spaceID}/${this.props.date}`)
    //         .once('value')
    //         .then( snapshot => {
    //             console.log(snapshot.val());
    //             if (snapshot.val() !== null) {
    //                 const bookingData = Object.values(snapshot.val());
    //                 console.log(bookingData);
    //                 this.setState({ reservationData: bookingData });
    //             }
    //         })
    //         .catch( e => {
    //             console.log("Error fetching data", e);
    //         });

    //     db.ref(`/spaces/${this.props.spaceID}`)
    //         .once('value')
    //         .then( snapshot => {
    //             const spaceData = snapshot.val()
    //             console.log(spaceData);
    //             this.setState({ spaceData: spaceData});
    //         })
    //         .catch( e => {
    //             console.log("Error fetching space data", e);
    //         });

    //   }

    render() {
        console.log("rendering...")
        return (<div>
            <p><b>Select your time range:</b></p>
            <br/>
            <div className='time-picker-container'>
                {this.createBtnList()}
            </div>
        </div>)
    }
    
}



export default TimePicker;