import db from "./../util/firebase";

/**
 * 
 * @param {number} capacity 
 * @param {string} spaceID 
 * @param {string} date 
 * @param {callback} callback 
 */
const checkAvailability = (capacity, spaceID, date, callback) => {
    console.log("Capacity: ", capacity);
    db.ref(`/reservations/${spaceID}/${date}`).once("value").then(snapshot => {
        if (snapshot.val() !== null) {
            const fetchedData = Object.values(snapshot.val());
            console.log(fetchedData);
            callback();
        } else {
            callback();
        }
        
    });
    
}

export default checkAvailability;