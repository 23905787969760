import * as firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyAmk7zbgi4ddTMWvKZOk_zc_udNuDlY5_A",
    authDomain: "simple-booking-733be.firebaseapp.com",
    databaseURL: "https://simple-booking-733be-default-rtdb.firebaseio.com",
    projectId: "simple-booking-733be",
    storageBucket: "simple-booking-733be.appspot.com",
    messagingSenderId: "431913787356",
    appId: "1:431913787356:web:a78ac3223375c69f10f919",
    measurementId: "G-5Z5TRT56VS"
  };

// const app = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);
const db = firebase.database();


export default db;

// if (dbLocation.length >= 2) {
//     db.ref(dbLocation + '/1662131546548').set({
//       spaceName: "Woodshop",
//       spaceID: "-NAyjCZG1a6nqBrY7oXk",
//       bookingID: "1662131546548",
//       time: 9.5,
//       duration: 2,
//       userName: "Aaron Yang",
//       timeSubmitted: 1662131546548,
//       dateSubmitted: "2022-09-02"
//   });
// } else {
//     alert('Please do not use root.');
// }

// db.ref()
// .once('value')
// .then( snapshot => {
//     const val = snapshot.val();
//     console.log(val);
// })
// .catch( e => {
//     console.log("Error fetching data", e);
// });