const toDisplayTime = (num) => {
    const halfHour = num % 1 === 0 ? ":00" : ":30";
    const hour = num % 1 === 0 ? num : num - 0.5;
    if (hour >= 0 && hour < 12) {
        return hour + halfHour + " am";
    } else if (hour === 12) {
        return 12 + halfHour + " pm";
    } else if (hour >= 12 && hour <= 24) {
        return (hour - 12) + halfHour + " pm";
    } else {
        console.error("Please enter a number between 0 and 24.");
        return "";
    }
}

export default toDisplayTime;