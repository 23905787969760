import React from 'react';
import db from './../../../../util/firebase';

class TimeRow extends React.Component {
    constructor(props){
        super(props);
    }

    removeBooking(spaceID, date, bookingID){
        db.ref(`/reservations/${spaceID}/${date}/${bookingID}/canceled`).set(true).then(() => {
            console.log("Booking data removed.", bookingID);
            // send an email to the user about the cancellation. 
        });
    }

    closeSlot(spaceID, date, time){
        console.log("close slot:", spaceID, date, time);
    }

    createUserList(reservationList){
        if (reservationList && reservationList.length > 0) {
            return reservationList.map((reservation, i) => {
                return (
                    <div key={i} className="user-tag">
                        <p>{reservation.fullName} {reservation.canceled ? "(Canceled)" : ''}</p> 
                        {reservation.canceled ? null : <button className='btn btn-remove' onClick={() => {
                            this.removeBooking(reservation.spaceID, reservation.date, reservation.bookingID);
                        }}>X</button>}
                        
                    </div>
                )
            });
        } else {
            return (
                <div className='empty-slot'>
                    <p>No one signed for this slot. </p>
                    <button className='btn btn-close-slot' onClick={() => {this.closeSlot(this.props.spaceID, this.props.date, this.props.time)}}>Close this Slot</button>
                </div>
            )
        }
        
    }

    render() {
        return (
            <div className='time-row'>
                <p className='display-time'>{this.props.displayTime}</p>
                <div className='user-list'>{this.createUserList(this.props.reservations)}</div>
            </div>
        )
    }
}

export default TimeRow;