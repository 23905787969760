import React from 'react';
import moment from 'moment';

import db from './../../util/firebase';

import TimeTable from './dashboard/TimeTable'
import SettingPanel from './dashboard/SettingPanel'
import SpaceCard from './../SpaceCard';

import './ManageDashboard.css'

const queryParams = new URLSearchParams(window.location.search);
const spaceID = queryParams.get('spaceID');
const buildingID = queryParams.get('buildingID');

class ManageDashboard extends React.Component {

  constructor(){
    super();
    this.state = {
      date: moment().format("YYYY-MM-DD"),
      bookingData: {},
      spaceData: {},
      managerData: {},
      buildingName: ''
    }
    this.loadBookingData = this.loadBookingData.bind(this);
    this.loadSpaceData = this.loadSpaceData.bind(this);
    this.dateChanged = this.dateChanged.bind(this);
    this.makeBooking = this.makeBooking.bind(this);
  }

  componentDidMount(){
      this.loadBookingData(`${spaceID}/${this.state.date}`);
      this.loadSpaceData(buildingID, spaceID);
  }

  loadBookingData(dataFolder){
      db.ref(`/reservations/${dataFolder}`).on("value", snapshot => {
          const data = snapshot.val();
          
          if(data !== null) {
            console.log(data);
            this.setState({
                bookingData: data
            });
          }
          
      });
  }

  loadSpaceData(building, space) {
      db.ref(`/buildings/${building}`).once("value").then(snapshot => {
          const data = snapshot.val();
          this.setState({
            spaceData: data.spaces[space]
          });
          this.setState({buildingName: data.name})
      });
  }

  makeBooking(){
    window.open(`/booking?spaceID=${spaceID}&buildingID=${buildingID}&buildingName=${this.state.buildingName}`);
  }

  dateChanged(e) {
    this.setState({
      date: e.target.value
    });
    this.loadBookingData(`${spaceID}/${e.target.value}`);
  }


  render() {
    if (Object.keys(this.state.spaceData).length > 0) {
      return (
        <div className="card-container">
          <h2>ManageDashboard</h2>
          <SpaceCard 
            name={this.state.spaceData.name}
            buildingID={this.state.spaceData.building} 
            spaceName={this.state.spaceData.name} 
            image={this.state.spaceData.image}
            spaceID={spaceID}
            room={this.state.spaceData.room}
            isPermitted={false}
            parent="manage"
            />
          
          <input type="date" value={this.state.date} onChange={this.dateChanged} />
          <button className='btn' onClick={this.makeBooking}>Make a new reservation</button>
          <button className='btn'>Export Reservations as a .csv file</button>
          <button className='btn'>Print</button>

          <SettingPanel managerID="manager-id-1" spaceID={spaceID} buildingID={buildingID}/>
          <TimeTable spaceData={this.state.spaceData} bookingData={this.state.bookingData} date={this.state.date}/>
        </div>
      );
    } else {
      return null;
    }
    
  }
    
}

export default ManageDashboard;