import React from 'react';

import cardImageStyle from './styles/cardImageStyle'

const BuildingCard = (props) => {

  console.log(props);
  const imgUrl = props.buildingData.image;
  const spaceUrl = `/spaces?buildingID=${props.buildingData.id}&buildingName=${props.buildingData.name}`;

  return (
    <a href={spaceUrl} className="card text-center" style={{margin: "16px"}}>
      <div className='card-img-top' style={cardImageStyle(imgUrl)}></div>
      <div className='card-body'>
        <h3 className='name'>{props.buildingData.name}</h3>
        {/* <a href={spaceUrl} className="btn-view-space btn btn-outline-primary" id={props.buildingData.id}>View Available Spaces</a> */}
      </div>
      
    </a>
  );
};

export default BuildingCard;