import React from 'react';
import db from './../util/firebase';

import BookingPreview from './BookingPreview';

const queryParams = new URLSearchParams(window.location.search);
const spaceID = queryParams.get('spaceID');
const date = queryParams.get('date');
const bookingID = queryParams.get('bookingID');



class Cancel extends React.Component {

    constructor() {
        super();
        this.state = {
            bookingData: {},
            message: ""
        }
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData(`${spaceID}/${date}/${bookingID}`);
    }


    loadData = (location) => {
        db.ref(`/reservations/${location}`).once("value").then(snapshot => {
            console.log(snapshot.val());
            this.setState({bookingData: snapshot.val()});
        });
    }

    displayBookingData = () => {
        // console.log(this.state.bookingData);
        if (!!this.state.bookingData) {
            return (
            
                <div>
                   {/* <p>{JSON.stringify(this.state.bookingData)}</p> */}
                   <h4>Are you sure you want to cancel the following reservation?</h4>
                    <br/>
                    <p>{this.state.message}</p>
                    <BookingPreview bookingData={this.state.bookingData} spaceData={null} toggle={this.togglePop} org="cancel"/>
                    <br/>
                    
                    <div className='text-center'>
                        <div className='row d-flex justify-content-around'>
                            <button onClick={this.cancelBooking} className="btn btn-secondary col-5">Yes, cancel this reservation</button>
                            <button onClick={this.back} className="btn btn-primary col-5 ">No, bring me back</button>
                        </div>
                        
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h4>Oops! Something is wrong.</h4>
                    <p>This reservation is not exist or has been deleted.</p>
                </div>
            )
        }
        
    }

    togglePop = () => {
        this.setState({
            previewDisplay: !this.state.previewDisplay
        });
    };

    cancelBooking = () => {  
        db.ref(`/reservations/${spaceID}/${date}/${bookingID}`).remove().then(() => {
            console.log(spaceID, "is cancelled.");
            this.setState({message: "Your reservation is deleted. You can close this window."});
        });
    }
    

    back = () => {
        if (window.history.length > 0) {
            window.history.back();
        } else {
            window.close();
        }
        
    }

    render() {
        return (
            <div style={{maxWidth: "36em", width: "100%"}}>
                {this.displayBookingData()}
            </div>
        )
    }
    
}

export default Cancel;