import React from "react";
import toDayName from './../../../../util/dayConverter';

import ScheduleBtn from "./ScheduleBtn";

class ScheduleSettingDay extends React.Component {
    
    constructor(props) {
        super(props);
    }

    toggleSelection(){

    }

    timeSelector (){

        const timeTable = [];
        for ( let i = 0; i < 24; i+=0.5) {
            timeTable.push(i);
        }
        
        return timeTable.map( hour => {
            console.log(this.props.dailySchedule)
            if (this.props.dailySchedule && this.props.dailySchedule.includes(hour)){
                
                return <ScheduleBtn 
                    isActive={true} 
                    hour={hour} 
                    key={hour} 
                    setTime={this.props.setTime}
                    day={this.props.day}/>
            } else {
                return <ScheduleBtn 
                    isActive={false} 
                    hour={hour} 
                    key={hour} 
                    setTime={this.props.setTime}
                    day={this.props.day}/>
            }
            
        })
    }

    render() {
        return (
            <div className='schedule-day'>
                <div className='time-container'>
                    <h5>{toDayName(this.props.day)}</h5>
                    <div className='row'>
                            {this.timeSelector()}
                    </div>
                </div>
                
            </div>
        )
    }
    
}

export default ScheduleSettingDay;