import moment from "moment";

const toDisplayDate = (dateStr, hasWeekDay=true) => {
    console.log(dateStr);
    if (hasWeekDay) {
        return `${moment(dateStr).format("dddd")}, ${moment(dateStr).format("MM/DD/YYYY")}`;
    } else {
        return `${moment(dateStr).format("MM/DD/YYYY")}`;
    }
    
}

export default toDisplayDate;