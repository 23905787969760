import React from 'react';

import cardImageStyle from './styles/cardImageStyle'

const SpaceCard = (props) => {

  const imgUrl = props.image;
  const spaceUrl = `/booking?spaceID=${props.spaceID}&buildingID=${props.buildingID}&buildingName=${props.buildingName}`;
  const permittted = props.isPermitted ? "active" : "disabled";
  // console.log(props);

  const handleReserveButton = () => {
    if(props.parent === "booking") {
      return null;
    } else if (props.parent === "manage") {
      return null;
    } else {
      return  <a href={spaceUrl} className={`btn-reserve ${permittted}`} id={props.spaceID}>Reserve</a>
    }
  }
  

  const loadMsg = (allowed) => {
    if (!allowed) {
      return null;
    } else {
      return (
        <div>
          <p>You must complete the training to use this facility.</p><span></span>
          <a href='https://content.sodaa360.com/woodshop-processing/' target={"_blank"} rel="noreferrer">Learn more</a>
        </div>
      )
    }
  }

  return (
    <div className={`card ${permittted}`}  style={{margin: "16px"}}>
      <div className='card-img-top' style={cardImageStyle(imgUrl)}></div>
      <div className='card-body'>
        <h4 className='name'>{props.name}</h4>
        {loadMsg(props.isPermitted)}
        <p className='details'><span>Room {props.room}</span></p>
        {handleReserveButton()}
      </div>
      
    </div>
  );
};

export default SpaceCard;