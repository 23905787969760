import React, {useState} from 'react';
import ScheduleSettingDay from './ScheduleSettingDay';


const SpaceSchedule = (props) => {
    
    const [schedule, setSchedule] = useState(props.schedule);

    const weekDays = [[],[],[],[],[],[],[]];

    props.schedule.forEach((day, i) => {
        weekDays[i] = day;
    });

    const addScheduleSetting = () => {
        
        return weekDays.map( (dailySchedule, i) => {
            return <ScheduleSettingDay key={`week_${i}`} id={`week_${i}`} setTime={props.setTime} dailySchedule={dailySchedule.openSlots} day={i}/>
        });
    }

    return (
        <div>
            <p>Schedule Setting</p>
            <div className="row">
                {addScheduleSetting()}
            </div>
            
        </div>
    )
}

export default SpaceSchedule;